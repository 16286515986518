import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        /*meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },*/
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        /*meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },*/
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    //Profile
    {
        path: '/profile',
        name: 'Profil',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/index')
    },

    //Trajets
    {
        path: '/newtrajet',
        name: 'Nouveau Trajet',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/add')
    },
    {
        path: '/trajets/publies',
        name: 'Trajets Publiés',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/publies')
    },
    {
        path: '/trajets/encours',
        name: 'Trajets Encours',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/encours')
    },
    {
        path: '/trajets/avenir',
        name: 'Trajets à Venir',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/avenir')
    },
    {
        path: '/trajets/effectues',
        name: 'Trajets Effectues',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/effectues')
    },
    {
        path: '/trajets/annules',
        name: 'Trajets Annules',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/annules')
    },
    {
        path: '/message/nouveau',
        name: 'Nouveau Message',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/message/new')
    },
    {
        path: '/message/historique',
        name: 'Historique Message',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/message/historique')
    },










    
    {
        path: '/auth/login-1',
        name: 'login-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/login-1')
    },
    {
        path: '/auth/register-1',
        name: 'register-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/register-1')
    },
    {
        path: '/auth/lock-screen-1',
        name: 'Lock-screen-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/lock-screen-1')
    },
    {
        path: '/auth/recoverpwd-1',
        name: 'Recoverpwd-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/recoverpwd-1')
    },
    
    //user
    {
        path: '/user/add_users',
        name: 'Nouveau utilisateur',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/addUser')
    },
    {
        path: '/user/users_list',
        name: 'Liste utilisateur',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/UserList')
    },
    {
        path: '/user/suspended_users',
        name: 'Liste utilisateurs suspendus',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/suspendu')
    },
    {
        path: '/user/user_info/:idUser',
        name: 'Information utilisateur',
        meta: { authRequired: true },
        props: route=>({
            idUser: route.params["idUser"]
        }),
        component: () => import('../views/pages/users/userinfo')
    },
    {
        path: '/user/verification/:idUser',
        name: 'Vérification utilisateur',
        meta: { authRequired: true },
        props: route=>({
            idUser: route.params["idUser"]
        }),
        component: () => import('../views/pages/users/verification')
    },
    //fin user

    //Réservation
    {
        path: '/reservation/add_reservation',
        name: 'Réservation',
        meta: { authRequired: true },
        component: () => import('../views/pages/reservation/add')
    },
    {
        path: '/reservation/reservation_list',
        name: 'Réservation',
        meta: { authRequired: true },
        component: () => import('../views/pages/reservation/reservationList')
    },
    // Fin réservation

    //admin
    {
        path: '/admin/add-admin',
        name: 'Administrateur',
        meta: { authRequired: true },
        component: () => import('../views/pages/administrateur/add_admin')
    },
    //fin admin

    // détails alertes 
    {// -------------------------don 
        path: '/alertes/details_alertes',
        name: 'Details alertes',
        meta: { authRequired: true },
        component: () => import('../views/pages/alertes/details_alertes')
    },
    // fin détails alertes

    //alerte trajet
    {
        path: '/trajets/alertes',
        name: 'alertes trajets',
        meta: { authRequired: true },
        component: () => import('../views/pages/trajets/alerte')
    },

    //fin alerte

    // transaction 
    {// -------------------------don 
        path: '/transactions/historique-transactions',
        name: 'Transactions',
        meta: { authRequired: true },
        component: () => import('../views/pages/transactions/historique')
    },
    // fin transaction
    
    //virement
    {
        path: '/virements/historique-virements',
        name: 'Virement',
        meta: { authRequired: true },
        component: () => import('../views/pages/virements/historique')
    },
    //fin virement

    // paiement
    {
        path: '/suivis/historique',
        name: 'Suivis de gains',
        meta: { authRequired: true },
        component: () => import('../views/pages/suivis-gains/historique')
    },
    //fin paiment
    //statistique utilisateur
    {
        path: '/user/statistique',
        name: 'Statistique',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/statistique')
    },
    //fin statistique

    // badge
    {
        path: '/user/badge',
        name: 'Statistique',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/badge')
    },
    // fin badge

    //details trajet_a_venir
    {
        path: '/trajets/details_avenir/:idtrajet',
        name: 'details trajets à venir  ',
        meta: { authRequired: true },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_avenir')
    },
    {
        path: '/trajets/details_effectues/:idtrajet',
        name: 'details trajets effectués',
        meta: { authRequired: true },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_effectues')
    },
    {
        path: '/trajets/details_encours/:idtrajet',
        name: 'details trajets en cours',
        meta: { authRequired: true },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_encours')
    },
    {
        path: '/trajets/details_annules/:idtrajet',
        name: 'details trajets annulés',
        meta: { authRequired: true },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_annules')
    },
    {
        path: '/trajets/urgences',
        name: 'urgences',
        meta: { authRequired: true },
        component: () => import('../views/pages/trajets/urgences')
    },
    //fin details trajets_a_venir
    //CodePromo
    {
        path: '/codepromo',
        name: 'codepromo',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/codepromo')
    },
    {
        path: '/vehicule/add_car',
        name: 'ajout',
        meta: { authRequired: true },
        component: () => import('../views/pages/vehicule/add')
    },
    {
        path: '/vehicule/list_car',
        name: 'liste',
        meta: { authRequired: true },
        component: () => import('../views/pages/vehicule/list')
    },
    {
        path: '/vehicule/details/:idvehicule',
        name: 'Détails véhicule',
        meta: { authRequired: true },
        props: route=>({
            idvehicule: route.params["idvehicule"]
        }),
        component: () => import('../views/pages/vehicule/details')
    },
    {
        path: '/location/automatique',
        name: 'location',
        meta: { authRequired: true },
        component: () => import('../views/pages/location/automatique')
    },
    {
        path: '/location/list',
        name: 'liste',
        meta: { authRequired: true },
        component: () => import('../views/pages/location/list')
    },
    {
        path: '/reservation/reservation',
        name: 'réservation véhicule',
        meta: { authRequired: true },
        component: () => import('../views/pages/reservation_vehicule/reservation')
    },
    {
        path: '/reservation/list',
        name: 'liste réservation',
        meta: { authRequired: true },
        component: () => import('../views/pages/reservation_vehicule/list')
    },
    //Retour sur Rmo
    {
        path: '/historique/retourner_rmo',
        name: 'retour sur RMobility',
        meta: { authRequired: true },
        component: () => import('../views/pages/historique/retourner_rmo')
    },
    //Historique des locations
    {
        path: '/historique/automatique',
        name: 'historique automatique',
        meta: { authRequired: true },
        component: () => import('../views/pages/historique/automatique')
    },
    {
        path: '/details/automatique',
        name: 'details automatique',
        meta: { authRequired: true },
        component: () => import('../views/pages/historique/details_auto')
    },
    {
        path: '/historique/manuelle',
        name: 'historique manuelle',
        meta: { authRequired: true },
        component: () => import('../views/pages/historique/manuelle')
    },
    {
        path: '/details/manuel',
        name: 'details manuel',
        meta: { authRequired: true },
        component: () => import('../views/pages/historique/details_manuel')
    },
    //Chauffeur
    {
        path: '/chauffeur/add',
        name: 'Ajout chauffeur',
        meta: { authRequired: true },
        component: () => import('../views/pages/chauffeur/add')
    },
    {
        path: '/chauffeur/list',
        name: 'historique manuelle',
        meta: { authRequired: true },
        component: () => import('../views/pages/chauffeur/list')
    },
    {
        path: '/chauffeur/details/:idchauffeur',
        name: 'Détails chauffeurs',
        meta: { authRequired: true },
        props: route=>({
            idchauffeur: route.params["idchauffeur"]
        }),
        component: () => import('../views/pages/chauffeur/details')
    },
    //Clients
    {
        path: '/client/add',
        name: 'Ajout chauffeur',
        meta: { authRequired: true },
        component: () => import('../views/pages/client/add')
    },
    {
        path: '/client/list',
        name: 'historique manuelle',
        meta: { authRequired: true },
        component: () => import('../views/pages/client/list')
    },
    {
        path: '/client/details/:idclient',
        name: 'Ajout chauffeur',
        meta: { authRequired: true },
        props: route=>({
            idclient: route.params["idclient"]
        }),
        component: () => import('../views/pages/client/details')
    },

/*     //Abonnements
    {
        path: '/abonnement',
        name: 'abonnement',
        meta: { authRequired: true },
        component: () => import('../views/pages/abonnements/abonnement')
    }, */


    //Agence
    {
        path:'/agence/add',
        name:'ajout',
        meta:{ authRequired: true},
        component: () => import('../views/pages/agence/add')
    },
    {
        path:'/agence/list',
        name:'liste',
        meta:{ authRequired: true},
        component: () => import('../views/pages/agence/list')
    },
    {
        path:'/location/manuelle',
        name:'liste',
        meta:{ authRequired: true},
        component: () => import('../views/pages/location/manuelle')
    },
    // Synthèse
    {
        path:'/synthese/automatique',
        name:'liste',
        meta:{ authRequired: true},
        component: () => import('../views/pages/synthese/automatique')
    },
    {
        path:'/synthese/manuelle',
        name:'liste',
        meta:{ authRequired: true},
        component: () => import('../views/pages/synthese/manuelle')
    },
    {
        path:'/synthese/synthese',
        name:'liste',
        meta:{ authRequired: true},
        component: () => import('../views/pages/synthese/synthese')
    },
    //vidange
    {
        path:'/vidange/vidange',
        name:'vidange',
        meta:{ authRequired: true},
        component: () => import('../views/pages/vidange/vidange')
    },
    {
        path:'/vidange/historique',
        name:'liste',
        meta:{ authRequired: true},
        component: () => import('../views/pages/vidange/historique')
    },
    // Suivi/Entretien
    {
        path:'/suivi/reparation',
        name:'liste',
        meta:{ authRequired: true},
        component: () => import('../views/pages/suivi-entretien/reparation')
    },
    {
        path:'/suivi/entretien',
        name:'liste',
        meta:{ authRequired: true},
        component: () => import('../views/pages/suivi-entretien/suivi')
    },

    // Avis
    /*{
        path:'/avis/avis',
        name:'liste',
        meta:{ authRequired: true},
        component: () => import('../views/pages/Avis/avis')
    },*/

    //Mot de passe
    {
        path:'/password/motpasse',
        name:'mot de passe',
        meta:{ authRequired: true},
        component: () => import('../views/pages/password/motdepasse')
    },
]
